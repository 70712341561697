<template>
  <div>
    <v-alert prominent type="info" text class="mb-4" v-if="showConfirmation">
      <v-row align="center">
        <v-col class="grow">
          Du kannst hier eine aktuelle Schulbestätigung als PDF-Datei
          herunterladen.
        </v-col>
        <v-col class="shrink">
          <ReportButton
            resource="report/schoolconfirmation"
            :parameters="{
              type: 'student',
              target: 'email',
              year: 'current',
              id: $_profilePerson.id,
            }"
            text
            color="info"
            >Schulbestätigung</ReportButton
          >
        </v-col>
      </v-row>
    </v-alert>

    <SchoolProfile :person="person" />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import ReportButton from "@/components/ReportButton.vue";

import SchoolProfile from "common/components/Person/SchoolProfile.vue";

export default defineComponent({
  name: "PersonStudentInfo",
  components: { ReportButton, SchoolProfile },
  props: ["person"],
  computed: {
    showConfirmation() {
      return this.$_hasRole("student");
    },
  },
});
</script>
